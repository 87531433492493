<template>
  <v-avatar color="accent">
    <img v-if="avatarExists" :src="avatar" :alt="fullName" />
    <span v-else class="white--text">{{ initials }}</span>
  </v-avatar>
</template>

<script>
export default {
  computed: {
    avatarExists() {
      return this.$store.getters.userAvatar ? true : false;
    },
    avatar() {
      return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/${this.$store.getters.userAvatar}`;
    },
    initials() {
      return this.$store.getters.userInitials;
    },
    fullName() {
      return this.$store.getters.userFullName;
    }
  }
};
</script>